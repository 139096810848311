<template>
  <div>
    <div
      ref="dropzoneRef"
      class="mb-4 w-full cursor-pointer rounded-lg border border-gray-200 bg-white px-6 py-4"
      :class="[
        {
          'pointer-events-none opacity-50': uploadFiles.length === maxFiles,
        },
        containerClass,
      ]"
    >
      <div
        class="pointer-events-none relative flex flex-col items-center justify-center"
      >
        <slot />
      </div>
    </div>

    <div
      v-for="uploadFile in uploadFiles"
      :key="uploadFile.upload?.uuid"
      class="mb-3 bg-white"
    >
      <div
        v-if="uploadFile.accepted"
        class="flex flex-col rounded-lg border border-gray-200 p-4"
      >
        <div class="flex justify-between">
          <div class="flex flex-col">
            <p class="text-sm font-medium text-gray-700">
              {{ uploadFile.name }}
            </p>
          </div>
          <div class="cursor-pointer" @click="onRemoveFile(uploadFile)">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 6V5.2C16 4.0799 16 3.51984 15.782 3.09202C15.5903 2.71569 15.2843 2.40973 14.908 2.21799C14.4802 2 13.9201 2 12.8 2H11.2C10.0799 2 9.51984 2 9.09202 2.21799C8.71569 2.40973 8.40973 2.71569 8.21799 3.09202C8 3.51984 8 4.0799 8 5.2V6M10 11.5V16.5M14 11.5V16.5M3 6H21M19 6V17.2C19 18.8802 19 19.7202 18.673 20.362C18.3854 20.9265 17.9265 21.3854 17.362 21.673C16.7202 22 15.8802 22 14.2 22H9.8C8.11984 22 7.27976 22 6.63803 21.673C6.07354 21.3854 5.6146 20.9265 5.32698 20.362C5 19.7202 5 18.8802 5 17.2V6"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
      <div
        v-else
        class="flex flex-col rounded-lg border border-danger-300 bg-danger-25 p-4"
      >
        <div class="flex justify-between">
          <div class="flex flex-col">
            <span class="text-sm font-medium text-danger-700">
              Upload failed, please try again
            </span>
            <span class="text-sm font-normal text-danger-600">
              {{ uploadFile.name }}
            </span>
          </div>
          <div class="cursor-pointer" @click="onRemoveFile(uploadFile)">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 6V5.2C16 4.0799 16 3.51984 15.782 3.09202C15.5903 2.71569 15.2843 2.40973 14.908 2.21799C14.4802 2 13.9201 2 12.8 2H11.2C10.0799 2 9.51984 2 9.09202 2.21799C8.71569 2.40973 8.40973 2.71569 8.21799 3.09202C8 3.51984 8 4.0799 8 5.2V6M10 11.5V16.5M14 11.5V16.5M3 6H21M19 6V17.2C19 18.8802 19 19.7202 18.673 20.362C18.3854 20.9265 17.9265 21.3854 17.362 21.673C16.7202 22 15.8802 22 14.2 22H9.8C8.11984 22 7.27976 22 6.63803 21.673C6.07354 21.3854 5.6146 20.9265 5.32698 20.362C5 19.7202 5 18.8802 5 17.2V6"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="errorMessage"
      class="flex flex-col rounded-lg border border-danger-300 bg-danger-25 p-4"
    >
      <div class="flex justify-between">
        <div class="flex flex-col">
          <span class="text-sm font-medium text-danger-700">
            {{ errorMessage }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Dropzone, { type DropzoneFile } from 'dropzone'

const emit = defineEmits([
  'update:modelValue',
  'onRemoveFile',
  'onChangeFiles',
  'onUploadFiles',
])

const props = defineProps({
  containerClass: {
    type: String,
    default: null,
  },
  modelValue: {
    type: [Array, Object],
    required: false,
    default: () => [],
  },
  errorMessage: {
    type: String,
    default: null,
  },
  // ['.svg', '.jpeg'...]
  acceptedFiles: {
    type: Array,
    default: () => [],
  },
  maxFiles: {
    type: Number,
    required: false,
    default: null,
  },
  maxFilesize: {
    type: Number,
    required: false,
    default: 10, // 10MB
  },
})

const dropzoneRef = ref()
const uploadFiles = ref<DropzoneFile[]>([])
const loading = ref(false)

//@ts-ignore
let dropzoneInstance: Dropzone = null

onMounted(() => {
  dropzoneInstance = new Dropzone(dropzoneRef.value as HTMLElement, {
    url: '#',
    autoProcessQueue: false,
    maxFiles: props.maxFiles,
    previewTemplate: '<div></div>',
    acceptedFiles: props.acceptedFiles.toString(),
    maxFilesize: props.maxFilesize,
  })

  dropzoneInstance.on('addedfile', function (file) {
    uploadFiles.value.push(file)
  })

  dropzoneInstance.on('addedfiles', function (uploadFiles) {
    const files = []
    for (const uploadFile of uploadFiles) {
      files.push(uploadFile)
    }

    emit(
      'onUploadFiles',
      files.filter((file) => file.accepted),
    )
    emitModelValue()
  })

  dropzoneInstance.on('removedfile', (file: DropzoneFile) => {
    uploadFiles.value = uploadFiles.value.filter(
      (uploadFile) => uploadFile.upload?.uuid !== file.upload?.uuid,
    )

    if (!file.accepted) {
      return
    }

    emit('onRemoveFile', file)
    emitModelValue()
  })
})

onBeforeUnmount(() => {
  //@ts-ignore
  dropzoneInstance.removeEventListeners()
})

onBeforeUnmount(() => {
  dropzoneInstance.off('addedfile')
  dropzoneInstance.off('addedfiles')
  dropzoneInstance.off('removedfile')
})

const onRemoveFile = (uploadFile: DropzoneFile) => {
  dropzoneInstance.removeFile(uploadFile)
}

const emitModelValue = () => {
  const emitFiles = uploadFiles.value.reduce(
    (uploadFiles: DropzoneFile[], uploadFile: DropzoneFile) => {
      if (!uploadFile.accepted) {
        return uploadFiles
      }

      return [...uploadFiles, uploadFile]
    },
    [],
  )

  const maxFiles = props.maxFiles

  const emitData = maxFiles === 1 ? emitFiles[0] : emitFiles
  emit('update:modelValue', emitData)
  emit('onChangeFiles', emitData)
}
</script>
